import './App.css';
import PhotoMe from "./images/me.png"

function App() {
  return (
    <div className="App">
      <h1>Hi, I'm Anthony!</h1>
      <img src={PhotoMe} />
      <h2>I bench 225</h2>
    </div>
  );
}

export default App;
